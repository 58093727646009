<template>
    <div>
        <v-card outlined flat class="text-left" v-if="notification">
            <v-progress-linear indeterminate color="primary" v-if="taskLoading"></v-progress-linear>

            <v-toolbar flat dense>
                <v-chip :color="getTaskStatus(notification.task).color" outlined small><v-icon small left>{{
                    getTaskStatus(notification.task).icon
                }}</v-icon>
                    {{ notification.task.status }}</v-chip>
                <v-spacer></v-spacer>
                <v-tooltip left>
                    <template v-slot:activator="{ open }">
                        <v-menu offset-y v-on="open">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon v-if="notification.flag == null">outlined_flag</v-icon>
                                    <v-icon v-else :color="getStatusColour(notification.flag)">flag</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item v-for="(status, index) in statuses" :key="index"
                                    @click="notification.flag = status.value, updateNotification(notification)"
                                    style="height: 30px">
                                    <v-list-item-action>
                                        <v-icon :color="status.color">{{ status.icon }}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ status.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                    <span>Priority</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" small icon class="dotted mx-0" @click="changeReadStatus(notification)"
                            v-if="!notification.read">
                            <v-icon small>done</v-icon>
                        </v-btn>
                        <v-btn v-on="on" small icon class="mx-0" @click="changeReadStatus(notification)" v-else
                            color="green">
                            <v-icon>check_circle</v-icon></v-btn>
                    </template>
                    <span>{{ notification.read ? 'Mark as unread' : 'Mark as read' }}</span>
                </v-tooltip>

            </v-toolbar>
            <v-card-title>
                {{ notification.title }}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-subtitle>
                {{ notification.description }}
            </v-card-subtitle>
            <v-card-text>
                <v-tabs grow flat v-model="tab">
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab> <v-icon left>info</v-icon> Info </v-tab>
                    <v-tab><v-icon left>description</v-icon> Attachments</v-tab>
                </v-tabs>
                <!-- Info tab -->
                <div v-if="tab == 0">
                    <v-list dense v-if="shipment">
                        <v-list-item @click="routeToShipment">
                            <v-list-item-action>
                                <v-icon>description</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ shipment.shipmentFile.fileNumber }}
                                </v-list-item-title>
                                <v-list-item-subtitle style="font-size: 14px">
                                    File Number
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-action>
                                <v-icon>location_on</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ shipment.portOfLoadValue }} -
                                    {{ shipment.finalDestinationValue }}
                                </v-list-item-title>
                                <v-list-item-subtitle style="font-size: 14px"
                                    v-if="shipment.portOfDischargeValue != shipment.finalDestinationValue">
                                    VIA
                                    {{ shipment.portOfDischargeValue }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row align="center" class="mt-0 pt-0">
                                    <v-col cols="4" class="mt-0 pt-0 text-center">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-avatar size="32" right v-on="on">
                                                    <v-img contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${shipment.originCountry.iso2.toLowerCase()}.svg`"></v-img>
                                                </v-avatar>
                                            </template>
                                            <span>{{ shipment.originCountry.name }}</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="4" class="mt-0 pt-0 text-center">
                                        <v-icon>arrow_forward</v-icon>
                                    </v-col>
                                    <v-col cols="4" class="mt-0 pt-0 text-center">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-avatar left size="32" v-on="on">
                                                    <v-img contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${shipment.destinationCountry.iso2.toLowerCase()}.svg`"></v-img>
                                                </v-avatar>
                                            </template>
                                            <span>{{ shipment.destinationCountry.name }}</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-action>
                                <v-icon>directions_boat_filled</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span v-if="shipment.vessel" style="color: var(--v-primaryText-base)">
                                        {{ shipment.vessel.name }}
                                    </span>
                                    <span v-else>{{ shipment.vesselName }}</span>
                                    <br />
                                    {{ shipment.voyage }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <span v-if="shipment.atd" style="font-size: 12px; color: var(--v-primaryText-base)">ATD:
                                    {{ formatDate(shipment.atd) }}</span>
                                <span v-else style="font-size: 12px; color: var(--v-primaryText-base)">ETD: {{
                                    formatDate(shipment.etd)
                                }}</span>
                                <!-- <br /> -->
                                <span v-if="shipment.ata" style="font-size: 12px; color: var(--v-primaryText-base)">ATA:
                                    {{ formatDate(shipment.ata) }}</span>
                                <span v-else style="font-size: 12px; color: var(--v-primaryText-base)">ETA: {{
                                    formatDate(shipment.eta)
                                }}</span>

                            </v-list-item-action>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-subheader style="font-size: 16px">Parties</v-subheader>
                        <v-divider></v-divider>
                        <v-list-item v-if="shipment.shipper">
                            <v-list-item-action>
                                <v-icon>outbound</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span style="font-size: 14px; color: var(--v-primaryText-base)">
                                        {{ shipment.shipper ? shipment.shipper.name : "-" }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="shipment.onBehalfShipper">
                                    on behalf of <a>{{ shipment.onBehalfShipper.name }}</a>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <b style="color: var(--v-primaryText-base)">Shipper</b>
                            </v-list-item-action>
                        </v-list-item>

                        <v-list-item v-if="shipment.forwarder">
                            <v-list-item-action>
                                <v-icon>fast_forward</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span style="font-size: 14px; color: var(--v-primaryText-base)">
                                        {{ shipment.forwarder ? shipment.forwarder.name : "-" }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="shipment.onBehalfForwarder">
                                    on behalf of <a>{{ shipment.onBehalfForwarder.name }}</a>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <b style="color: var(--v-primaryText-base)">Forwarder</b>
                            </v-list-item-action>
                        </v-list-item>

                        <v-list-item v-if="shipment.buyer">
                            <v-list-item-action>
                                <v-icon>shopping_cart</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span style="font-size: 14px; color: var(--v-primaryText-base)">
                                        {{ shipment.buyer ? shipment.buyer.name : "-" }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="shipment.onBehalfBuyer">
                                    on behalf of <a>{{ shipment.onBehalfBuyer.name }}</a>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <b style="color: var(--v-primaryText-base)">Buyer</b>
                            </v-list-item-action>
                        </v-list-item>

                        <v-list-item v-if="shipment.consignee">
                            <v-list-item-action>
                                <v-icon>call_received</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span style="font-size: 14px; color: var(--v-primaryText-base)">
                                        {{ shipment.consignee ? shipment.consignee.name : "-" }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="shipment.onBehalfConsignee">
                                    on behalf of <a>{{ shipment.onBehalfConsignee.name }}</a>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <b style="color: var(--v-primaryText-base)">Consignee</b>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </div>
                <!-- Attachments tabs -->
                <div v-else-if="tab == 1">
                    <v-list dense style="max-height: 50vh; min-height: 10vh;
                        overflow-y: auto;
                      " :key="taskAttachmentKey">
                        <v-list-item v-for="doc in taskAttachments.filter(
                            (x) => x.file
                        )" :key="doc.id">
                            <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                                <v-icon v-if="doc.required && !doc.file" color="orange">warning</v-icon>
                                <v-icon v-else-if="doc.required && doc.file" color="green">check</v-icon>
                                <v-img v-else-if="getDocumentIcon(doc.name)" class="mr-2"
                                    style="height: 30px; width: 30px" :src="getDocumentIcon(doc.name)">
                                </v-img>
                                <v-icon v-else-if="doc.file" color="green">check</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ doc.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="doc.file">
                                    {{ formatDate(doc.createdAt) }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else>
                                    <i v-if="doc.required">Required</i>
                                    <i v-else>Not Required</i>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action :key="documentKey">
                                <v-row :key="documentKey">
                                    <v-btn :key="documentKey" :loading="docLoading == doc.id" icon
                                        v-if="doc.file && (doc.file.toLowerCase()).includes('.pdf')" small
                                        @click="previewDocument(doc)"><v-icon small>visibility</v-icon></v-btn>
                                    <v-btn v-if="doc.file" icon :loading="docDownloading == doc.id"
                                        @click="downloadDocument(doc)" small><v-icon small>download</v-icon></v-btn>
                                </v-row>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-if="
                            taskAttachments.filter((x) => x.file).length == 0
                        ">
                            <v-list-item-content class="text-center">
                                <span style="color: grey">No attachments.</span>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
            </v-card-text>
        </v-card>

        <v-dialog v-model="previewModal" :fullscreen="$vuetify.breakpoint.mobile || fullscreen" width="1200px">
            <v-card flat>
                <v-toolbar flat>
                    <v-toolbar-title style="font-size: 16px">
                        {{ previewItem.name }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
                            v-else>fullscreen_exit</v-icon></v-btn>
                    <v-btn icon @click="(previewModal = false), (previewItem = {})">X</v-btn>
                </v-toolbar>
                <v-card-text :style="{
                    'max-height': fullscreen ? '88vh' : '75vh',
                    'overflow-y': 'auto',
                    'background-color': 'var(--v-modal-base) !important',
                }">
                    <v-row justify="center">
                        <v-col cols="12" md="9" lg="8" xl="6" class="text-center">
                            <!-- <v-btn @click="annotate">Annotate</v-btn> -->
                            <div ref="pdfBox">
                                <pdf style="display: none;" :src="previewItem.file"
                                    @num-pages="pageCount = new Array($event)" />
                                <pdf v-for="(page, index) in pageCount" :key="index" :src="previewItem.file"
                                    :page="index + 1" class="contract-container" />
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import dateFormat from "dateformat";
import pdf from "vue-pdf";
export default {
    props: ['notification'],
    components: {
        pdf
    },
    data: () => ({
        documentKey: 1000,
        docDownloading: null,
        docLoading: null,
        fullscreen: false,
        pageCount: [],
        previewItem: {},
        previewModal: false,
        statuses: [
            { name: 'Urgent', color: 'red', icon: 'flag', value: 'Urgent' },
            { name: 'High', color: 'amber', icon: 'flag', value: 'High' },
            { name: 'Normal', color: 'blue lighten-3', icon: 'flag', value: 'Normal' },
            { name: 'Low', color: 'green', icon: 'flag', value: 'Low' },
            { name: 'Clear', color: 'red', icon: 'close', value: null }
        ],
        shipment: null,
        shipmentLoading: false,
        tab: 0,
        task: null,
        taskAttachments: [],
        taskAttachmentKey: 5000,
        taskLoading: false
    }),
    watch: {
        'notification.id': {
            handler: function () {
                this.getTask()
                this.getTaskAttachments()
                if (this.notification.shipment) { this.getShipment() }
            },
            immediate: true
        }
    },
    methods: {
        async changeReadStatus(item) {
            item.read = !item.read
            await this.updateNotification(item)
            // this.notifications.splice(this.notifications.findIndex(x => x.id == item.id), 1)
        },
        async downloadDocument(doc) {
            this.docDownloading = doc.id
            this.documentKey++;
            let file = await this.$API.downloadFile({
                key: doc.file,
            });
            doc.fullFile = file.file;
            doc.fileType = file.fileType;
            var hiddenElement = document.createElement("a");
            hiddenElement.href = doc.fullFile;
            hiddenElement.target = "_blank";
            hiddenElement.download = doc.name;
            hiddenElement.click();
            this.docDownloading = null;
            this.documentKey++;
        },
        formatDate(date) {
            let result = null;
            if (date) {
                result = dateFormat(date, "dd/mm/yyyy");
            }
            return result;
        },
        getDocumentIcon(name) {
            let type = name.split(".");
            let result = null;
            if (name.includes(".") && type.length > 0) {
                result = type.at(-1);
            }
            if (result == "xlsx") {
                result = "xls";
            }
            return result ? `/icons/${result}.svg` : null;
        },
        getTaskStatus(task) {
            switch (task.status) {
                case "New":
                    return { name: task.status, icon: "grade", color: "orange" };
                case "Not started":
                    return { name: task.status, icon: "schedule", color: "grey" };
                case "Pending":
                    return { name: task.status, icon: "pending", color: "orange" };
                case "In Progress":
                    return { name: task.status, icon: "sync", color: "blue" };
                case "Complete":
                    return { name: task.status, icon: "task_alt", color: "green" };
                case "File Generated":
                    return { name: task.status, icon: "task", color: "orange" };
                case "Success":
                    return { name: task.status, icon: "task_alt", color: "green" };
                case "Cancelled":
                    return { name: task.status, icon: "cancel", color: "grey" };
                case "Not Created":
                    return { name: task.status, icon: "error", color: "red" };
                case "Rejected":
                    return { name: task.status, icon: "cancel", color: "red" };
                case "Draft":
                    return { name: task.status, icon: "edit_note", color: "orange" };
                case "Error":
                    return { name: task.status, icon: "error", color: "red" };
                case "Failed":
                    return { name: task.status, icon: "cancel", color: "red" };
                case "Submitted":
                    return { name: task.status, icon: "mark_email_read", color: "blue" };
                case "Not Required":
                    return { name: task.status, icon: "close", color: "grey" };
                case "Approved":
                    return { name: task.status, icon: "fact_check", color: "green" };
                default:
                    return {
                        icon: "info",
                        color: "grey",
                        name: task.status,
                    };
            }
        },
        getStatusColour(flag) {
            let find = this.statuses.find(x => x.value == flag)
            return find.color
        },
        async updateNotification(notification) {
            try {
                this.savingNotification = notification.id
                await this.$API.updateNotification(notification)
                this.savingNotification = null
            } catch (error) {
                console.log(error)
            }
        },
        async getTask() {
            try {
                this.taskLoading = true
                this.task = await this.$API.getTask(this.notification.taskId)
                this.taskLoading = false
            } catch (error) {
                console.log(error)
            }
        },
        async getShipment() {
            try {
                this.shipmentLoading = true
                this.shipment = await this.$API.getSingleShipment(this.notification.shipment.shipmentReference);
                this.shipmentLoading = false
            } catch (error) {
                console.log(error)
            }
        },
        async getTaskAttachments() {
            try {
                this.taskLoading = true
                this.taskAttachments = await this.$API.getTaskAttachments(this.notification.taskId)
                this.taskLoading = false
                this.taskAttachmentKey++
            } catch (error) {
                console.log(error)
            }
        },
        async previewDocument(doc) {
            this.docLoading = doc.id;
            this.documentKey++
            this.previewItem = this.documents.find(x => x.key == doc.file)
            if (!this.previewItem) {
                this.previewItem = {}
            }
            let file = await this.$API.downloadFile({
                key: doc.file,
            });
            this.previewItem.file = file.file;
            this.previewItem.fileType = file.fileType;
            this.previewModal = true
            this.docLoading = null
            this.documentKey++
        },
        routeToShipment(){
            this.$router.push({path: '/documentation/'+this.shipment.shipmentReference})
        }
    }
}
</script>